<template>
  <div>
    <h1>PERMISSION</h1>
    <div>{{userPermissions}}</div>
  </div>
</template>

<script>
/* disable eslint */
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      url: "",
    };
  },
  computed: {
    ...mapGetters(["userPermissions"]),
  },
  methods: {
    getData() {
      this.$store.commit("getData", this.url);
    },
  },
};
</script>
